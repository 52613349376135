import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
// import Footer from '@/components/Footer'


export default [
  {
    path: '/',
    components: {
      header: Navbar,
      default: () => import('@/views/qabilet')
    }
  },
  {
    path: '/medal-elbasy',
    components: {
      header: Navbar,
      default: () => import('@/views/medal-elbasy')
    }
  },
  {
    path: '/qabilet',
    components: {
      header: Navbar,
      default: () => import('@/views/qabilet')
    }
  },
  {
    path: '/myn-bala',
    components: {
      header: Navbar,
      default: () => import('@/views/mynBala')
    }
  },
  {
    path: '/myn-bala2021',
    components: {
      header: Navbar,
      default: () => import('@/views/mynBala2021')
    }
  },
  {
    path: '/myn-bala-old',
    components: {
      header: Navbar,
      default: () => import('@/views/mynBalaOld')
    }
  },
  {
    path: '/independence-game',
    components: {
      header: Navbar,
      default: () => import('@/views/independence-game')
    }
  },
  {
    path: '/medal-elbasy',
    components: {
      header: Navbar,
      default: () => import('@/views/medal-elbasy')
    }
  },
  {
    path: '/birge-oqy',
    components: {
      header: Navbar,
      default: () => import('@/views/birge-oqy')
    }
  },

  {
    path: '*',
    components: {
      header: Navbar,
      default: Empty
    }

  }
]

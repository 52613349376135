import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
window.independenceToken='-J4UjrBRHvxuPwUBMtCbZihPpFduBTut'
window.medalElbasyLogin='ahmediev1777@gmail.com'
window.medalElbasyPassword='123123'

switch (window.location.hostname) {
  case 'localhost':
    window.mynbala = 'https://api1000bala.elumiti.kz'
    window.API_ROOT = 'https://apiqabilet.elumiti.kz'
    window.OSH_API_ROOT = 'https://api.birgeoqy.kz'
    window.medalElbasy='https://dbmebk.elbasyacademy.kz'
    window.independenceGame='https://eoiapi.kzgame.kz'
    break;
  default:
    window.mynbala = 'https://api1000bala.elumiti.kz'
    window.API_ROOT = 'https://apiqabilet.elumiti.kz'
    window.OSH_API_ROOT = 'https://api.birgeoqy.kz'
    window.medalElbasy='https://dbmebk.elbasyacademy.kz'
    window.independenceGame='https://eoiapi.kzgame.kz'
}

Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}

Vue.http.options.progress = function (event) {
  if (event.lengthComputable) {
    NProgress.set(event.loaded / event.total)
  }
}

Vue.http.interceptors.push((request, next) => {

  NProgress.start()
  if (request.url.split('/')[2]!=window.independenceGame.substring(8)){
    request.headers.set('X-locale', localStorage.getItem('locale'))
  }

  if (request.url.split('/')[2]==window.independenceGame.substring(8)){
    if ( localStorage.getItem('independenceGameToken')) {

      request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('independenceGameёToken'))
    }
  }
  if (request.url.split('/')[2]==window.medalElbasy.substring(8)){
    if ( localStorage.getItem('MedalElbasyToken')) {

      request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('MedalElbasyToken'))
    }
  }

  else if (request.url.split('/')[2]==window.independenceGame.substring(8)){
      request.headers.set('Origin', window.location.hostname)
      request.headers.set('Authorization', 'Bearer ' + window.independenceToken)

  }


  next((response) => {
    NProgress.done()
    // if (response.status === 401) {
    //   return response
    // }
    // if (response.status === 422) {
    //   Vue.toastr({
    //     message: 'Error',
    //     description: (response.body.message) ? response.body.message : 'Неверно указаны данные',
    //     type: 'error'
    //   })
    // }
    // else if (response.status >= 400) {
    //   Vue.toastr({
    //     message: 'Error',
    //     description: (response.body.message) ? response.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
    //     type: 'error'
    //   })
    // }
    return response
  })
})
